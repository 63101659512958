const isPlatformBrowser = () =>
	typeof window !== 'undefined' && window.localStorage;

export const persistData = (key, value) =>
	isPlatformBrowser() ? localStorage.setItem(key, JSON.stringify(value)) : null;

export const readData = key =>
	isPlatformBrowser() ? JSON.parse(localStorage.getItem(key)) : null;

export const removeData = key =>
	isPlatformBrowser() ? localStorage.removeItem(key) : null;
