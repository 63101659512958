import React, { useReducer } from 'react';
import { persistData, readData, removeData } from '../libs/local-storage';

const currentVersion = '1.2';
const version = readData('version');

if (version === null || version !== currentVersion) {
	removeData('items');
	persistData('version', currentVersion);
}

const initialState = {
	items: readData('items') || [],
	topBar: { message: 'This is a test', active: false },
};

export const GlobalStateContext = React.createContext(initialState);
export const GlobalDispatchContext = React.createContext();

function createCartItem(state, item) {
	if (state.items.find(i => i.path === item.path && i.amount !== item.amount))
		return updateCartItem(state, item.path, item.amount);
	else if (!state.items.find(i => i.path === item.path)) state.items.push(item);
	persistData('items', state.items);
	return { ...state };
}

function deleteCartItem(state, path) {
	const filtered = state.items.filter(item => item.path !== path);
	persistData('items', filtered);
	return { ...state, items: filtered };
}

function updateCartItem(state, path, amount) {
	const items = state.items;
	const index = state.items.findIndex(i => i.path === path);
	items[index].amount = amount;
	persistData('items', items);
	return { ...state };
}

function destroyCart(state) {
	localStorage.clear();
	return { ...state, items: [] };
}

function createTopBar(state, message) {
	const { topBar } = state;
	topBar.message = message;
	topBar.active = true;
	return { ...state };
}

function destroyTopBar(state, message) {
	const { topBar } = state;
	topBar.message = message;
	topBar.active = false;
	return { ...state };
}

function reducer(state, action) {
	switch (action.type) {
		case 'CREATE_CART_ITEM': {
			return createCartItem(state, action.item);
		}
		case 'DELETE_CART_ITEM': {
			return deleteCartItem(state, action.path);
		}
		case 'UPDATE_CART_ITEM': {
			return updateCartItem(state, action.path, action.amount);
		}
		case 'DESTROY_CART': {
			return destroyCart(state);
		}
		case 'CREATE_TOPBAR': {
			return createTopBar(state, action.message);
		}
		case 'DESTROY_TOPBAR': {
			return destroyTopBar(state);
		}
		default:
			throw new Error('Bad action type');
	}
}

function GlobalContextProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>
				{children}
			</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	);
}

export default GlobalContextProvider;
